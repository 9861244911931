export const About =()=>{

    return(
        <>
        <section id="about" >
            <div>
                <img src="" alt="" />
                <div className="textcontent">
                    <p className="heading">
                    </p>
                    <p > Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci, aliquid unde, sint et, illum error facilis deserunt nulla vitae praesentium nesciunt quidem porro. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Qui vitae sit ipsa saepe, eveniet voluptatibus non laborum odit exercitationem, earum corporis dignissimos. Soluta, corporis repellat vitae voluptates, beatae asperiores ea fugiat dolores modi recusandae eos aliquid cumque eligendi tempore ab consectetur? Cum totam harum iste libero mollitia dignissimos corrupti assumenda! Excepturi enim rem illum esse!</p>
                </div>
            </div>
        </section>
        </>
    )
}